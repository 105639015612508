import React, {useState} from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Layout from '../components/Layout';
import YAMLData from '../../static/content/contact.yml';
import FooterData from "../../static/content/footer.yml";
import PageBanner from '../components/PageBanner';
import ReactMarkdown from "react-markdown";
import SEO from "../components/SEO";
import { Formik } from 'formik';
import clsx from 'clsx';

function ContactPage() {
  const imageData = useStaticQuery(graphql`
    query {
      banners: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 1400
              )
            }
          }
        }
      }
    }
  `);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const {
    heading,
    image,
    image_alt,
    info,
    email
  } = YAMLData;

  const {
    storeOne,
    storeTwo
  } = FooterData;

  const headerImage = imageData.banners.edges.find(
    headerImage => "/assets/"+headerImage.node.relativePath === image
  );

  function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
  }

  const init_values = {name: '', email: '', phone: '', message: ''};

  return (
    <Layout>
      <SEO title="Fair Beauty - Contact Us."
        description="Call us today for an informal chat and to find out more, or pop in to one of our salons. Pick up one of our extensive treatment lists and take a look around. Next time you visit it will feel like home." />
      <PageBanner image={headerImage.node.childImageSharp.gatsbyImageData}
        imageAlt={image_alt}
        heading={heading} />
      <section className="section section--contact">
        <div className="container grid has-centered is-flex-start">
          <Formik initialValues={init_values}
            validate={values => {
              const errors = {};
              if(!values.email) {
                errors.email = 'Required';
              }
              else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address'
              }
              if(!values.name) {
                errors.name = 'Required';
              }
              if(!values.message) {
                errors.message = 'Required';
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                setSubmitting(false);
                fetch("/", {
                  method: "POST",
                  headers: {"Content-Type": "application/x-www-form-urlencoded"},
                  body: encode({
                    "form-name": "Contact",
                    ...values
                  })
                })
                .then(response => {
                  setSubmitSuccess(true);
                  resetForm(init_values);
                })
                .catch(error => {
                })
              }, 400);
            }}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form className="column contact-form is-full is-desktop-5 grid is-vertical has-no-padding"
                data-netlify="true"
                onSubmit={handleSubmit}
                name="Contact">
                {submitSuccess &&
                  <div className="column is-full">
                    Your enquiry has been received and we will be in touch asap.
                  </div>
                }
                <input type="hidden"
                  name="form-name"
                  value="Contact" />
                <div className="column">
                  <label htmlFor="name">Name</label>
                  <input type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    className={clsx({
                      'has-error': errors.name && touched.name
                    })} />
                  {errors.name && touched.name
                    && (
                      <div className="form-error">
                        {errors.name}
                      </div>
                    )}
                </div>
                <div className="column">
                  <label htmlFor="email">Email</label>
                  <input type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    className={clsx({
                      'has-error': errors.email && touched.email
                    })} />
                  {errors.email && touched.email
                    && (
                      <div className="form-error">
                        {errors.email}
                      </div>
                    )}
                </div>
                <div className="column">
                  <label htmlFor="phone">Phone</label>
                  <input type="tel"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone} />
                </div>
                <div className="column">
                  <label htmlFor="message">Message</label>
                  <textarea name="message"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    className={clsx('has-resize-vertical', {
                      'has-error': errors.message && touched.message
                    })} ></textarea>
                  {errors.message && touched.message
                    && (
                      <div className="form-error">
                        {errors.message}
                      </div>
                    )}
                </div>
                <div className="column">
                  <button className="button is-primary is-full"
                    type="submit"
                    disabled={isSubmitting}>
                      Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
          <div className="column is-full has-no-padding is-tablet-down divider">
            <hr />
          </div>
          <div className="column details is-full is-desktop-5 is-offset-desktop-1">
            <h4>Fair Beauty</h4>
            <ReactMarkdown children={info} />
            <div style={{padding: "20px 0"}}
              className="has-link-inherit">
              <div>
                <i className="fas fa-mobile-alt fa-lg" style={{width: '30px'}}></i>
                <b>{storeOne.heading}</b>
              </div>
              <div style={{marginLeft: '30px'}}>
                <a href={'tel:'+storeOne.phone}>{storeOne.phone}</a>
              </div>
              <br />
              <div>
                <i className="fas fa-envelope" style={{width: '30px'}}></i>
                <b>Email</b>
              </div>
              <div style={{marginLeft: '30px'}}>
                <a href={'mailto:'+email}>{email}</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage;
